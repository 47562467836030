import React, { useRef, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional CSS for blur effect


const LazyImage = ({ src, alt, cls }) => {
  const imageRef = useRef();
  const [imageSrc, setImageSrc] = useState('./images/projects/placeholder.png');

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          imageRef.current.src = src;
          setImageSrc(src);
          observer.unobserve(imageRef.current);
        }
      },
      { threshold: 0.5 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [src]);

  return <img className={`${cls}`} ref={imageRef} src={imageSrc} alt={alt} effect="blur"/>;
};

export default LazyImage;
