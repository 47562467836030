import React, {useState, useEffect, useRef} from 'react';
import '../styles/home.css'
import { Link } from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import { TiFlashOutline } from 'react-icons/ti';
import { FiDownloadCloud } from 'react-icons/fi';
import { SiSlideshare } from 'react-icons/si';
import { PiPlugsConnected } from 'react-icons/pi';
import { TbMessages } from 'react-icons/tb';
import { BiVideoRecording } from 'react-icons/bi';

import {FaRibbon} from 'react-icons/fa'
import {IoIosApps} from 'react-icons/io'
import {HiMiniUsers} from 'react-icons/hi2'
import {PiShootingStarFill} from 'react-icons/pi'

import { motion, AnimatePresence } from 'framer-motion';
import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import Projects from '../components/Projects';


export default function Home() {

    const transition = { duration: 0.5, ease: "easeInOut" };

    const [activeIndex, setActiveIndex] = useState(0);
    const [scrolling, setScrolling] = useState(false);

    /*

    const scrollToSection = (index) => {
        if (index >= 0 && index <= 3) {
          setActiveIndex(index);
          window.scrollTo({
            top: window.innerHeight * index,
            behavior: 'smooth',
          });
        }
    };

    const handleScroll = (e) => {
        if (!scrolling) {
          if (e.deltaY > 0) {
            scrollToSection(activeIndex + 1);
          } else if (e.deltaY < 0) {
            scrollToSection(activeIndex - 1);
          }
        }
    };
    
    const handleKeyDown = (e) => {
        if (!scrolling) {
          if (e.key === 'ArrowDown') {
            scrollToSection(activeIndex + 1);
          } else if (e.key === 'ArrowUp') {
            scrollToSection(activeIndex - 1);
          }
        }
    };
    
    useEffect(() => {
        console.log(activeIndex);
        window.addEventListener('wheel', handleScroll);
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('wheel', handleScroll);
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeIndex, scrolling]);
    
    */
  


    /** -------------------------------------------------------- */




    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    const stats = [
        {
            number: '5+',
            name: 'Years of Dedication to the Craft',
            icon: FaRibbon,
            color: '#F5CAE6'
        },
        {
            number: '160+',
            name: 'Projects Launched into the Digital Universe',
            icon: IoIosApps,
            color: '#AFB7FF'
        },
        {
            number: '130+',
            name: 'Collaborated with Happy Clients Worldwide',
            icon: HiMiniUsers,
            color: '#EDE2AB'
        },
        {
            number: '110+',
            name: 'Stellar Ratings and Positive Feedback',
            icon: PiShootingStarFill,
            color: '#7DF2BF'
        },
    ]

    const langs = [
        { name: 'HTML', icon: 'html' },
        { name: 'CSS', icon: 'css' },
        { name: 'Bootstrap', icon: 'bootstrap' },
        { name: 'Tailwind CSS', icon: 'tailwind' },
        { name: 'Photoshop', icon: 'photoshop' },
        { name: 'JS', icon: 'js' },
        { name: 'React', icon: 'react' },
        { name: 'Vue JS', icon: 'vue' },
        { name: 'PHP', icon: 'php' },
        { name: 'Node JS', icon: 'node' },
        { name: 'MySQL', icon: 'mysql' },
    ];

    const skillcats = [
        {
            name: <span><span style={{color: '#F5CAE6'}}>Frontend</span> Magic</span>,
            icon: FaRibbon,
            color: '#000000'
        },
        {
            name: <span><span style={{color: '#AFB7FF'}}>Backend</span> Wizardry</span>,
            icon: IoIosApps,
            color: '#000000'
        }
    ]

    const features = [
        {
          name: 'Advanced real-time video technology for ultra-low latency and crystal clear video and audio.',
          icon: TiFlashOutline,
          color: '#F5CAE6'
        },
        {
          name: 'Download desktop and for Windows, Mac, Android and iOS.',
          icon: FiDownloadCloud,
          color: '#AFB7FF'
        },
        {
          name: 'Screen sharing, real time interactions and document sharing to get work done effectively.',
          icon: SiSlideshare,
          color: '#70A4ED'
        },
        {
          name: 'Global coverage, connect with anyone, from anywhere at anytime.',
          icon: PiPlugsConnected,
          color: '#E8DA91'
        },
    ]


      
  const originalName = "Usman Malik";
  const [displayedName, setDisplayedName] = useState('Usman Malik');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const typewriterInterval = setInterval(() => {
      if (currentIndex >= originalName.length) {
        setIsPaused(true);
        setTimeout(() => {
          setIsPaused(false);
          setCurrentIndex(0);
        }, 1500); // Pause for 2 seconds after displaying full name
      } else {
        let newDisplayedName = '';
        for (let i = 0; i < originalName.length; i++) {
          if (i === 0) {
            newDisplayedName += originalName[i]; // Keep the first letter unchanged
          } else if (i <= currentIndex) {
            newDisplayedName += originalName[i];
          } else if (originalName[i] === ' ') {
            newDisplayedName += ' ';
          } else {
            newDisplayedName += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
          }
        }
        setDisplayedName(newDisplayedName);
        setCurrentIndex(currentIndex + 1);
      }
    }, 50); // Typewriting interval

    return () => clearInterval(typewriterInterval);
  }, [currentIndex, originalName]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Set the duration in milliseconds

    return () => clearTimeout(timeout);
  }, []);

  return (
    <HomeLayout>
        <AnimatePresence mode='wait'>
        {isLoading && (
          <motion.div
            className="loader"
            initial={{ y: 0, opacity: 1 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: '-100%', opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <motion.div
              className='min-h-screen w-full bg-main flex flex-col justify-center items-center'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className='max-w-screen-2xl w-full text-9xl text-black font-bold tracking-wider border-l-[6px] pl-4'
                initial={{ opacity: 0, y: 100, x: 0, fontSize: '8rem', borderColor: 'transparent', textAlign: 'center' }}
                animate={{ opacity: 1, y: -100, x: 0, fontSize: '1.875rem', borderColor: 'black', textAlign: 'left' }}
                transition={{ duration: 2, delay: 1 }}
              >
                USMAN MALIK
              </motion.div>
              {/* Additional loader animations can be added here */}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isLoading && (
        <>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <main className="fullpage-containerx">
        <section className='sectionx main-home py-5 main w-full min-h-screen pt-28 flex flex-col justify-evenly'>
        {/* <motion.div key={0} className={'section'} initial={{ opacity: 0, y: '100%' }} animate={ 0 === activeIndex ? { opacity: 1, y: '0%' } : { opacity: 0, y: (activeIndex + 1 == 0) ? '100%' : '-100%' } } exit={ (activeIndex+1) == 0 ? { opacity: 0, y: '-100%' } : { opacity: 0, y: '0%' }} transition={{ duration: 0.25 }} > */}
            <Particles canvasClassName='!absolute' className='absolute min-h-screen h-full w-full' id="tsparticles" url="/particles.json" init={particlesInit}/>
            <div className="max-w-screen-2xl mx-auto px-4 mt-10 relative">
            <motion.img
                className='absolute right-0 h-[22rem] -top-10 z-10'
                src='/images/webdev.svg'
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
            />
            <div className="w-full relative z-20">
                <motion.div className='text-3xl font-bold mb-5 text-main tracking-wider border-l-[6px] pl-4 border-main' initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.5 }} >
                {displayedName}
                </motion.div>
                <motion.div className='text-7xl font-normal mb-5 text-white tracking-wider leading-tight -ml-1' initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.7 }} > Full Stack <span className='font-bold'>Developer</span>
                </motion.div>
                <motion.p className='leading-loose w-3/5' initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.9 }} >
                <span className='text-lg'>👋</span> Hello there! I'm Usman Malik, a passionate full stack developer dedicated to transforming ideas into digital realities. With a keen eye for design and a knack for coding, I'm here to showcase my journey of creating immersive websites and innovative apps.
                </motion.p>
            </div>
            </div>
            <div className=''>
                <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 1.3 }}  className='p-4 max-w-screen-2xl mx-auto mt-5 grid grid-cols-4 gap-5'>
                    {stats.map((stat) => (
                    <div key={stat.name} className={`p-8 rounded-3xl text-black bg-[${stat.color}]`} style={{background: stat.color}}>
                        <div className='flex flex-row space-x-3 items-center mb-3'>
                            <div className='bg-[#00000030] p-2 h-12 w-12 flex justify-center items-center rounded-full'>
                                <stat.icon className="h-5 w-5 text-black"/>
                            </div>
                            <div className='text-3xl font-bold'>{stat.number}</div>
                        </div>
                        <div className='font-medium text-[15px]'>{stat.name}</div>
                    </div>
                    ))}
                </motion.div>
            </div>
        {/* </motion.div> */}
        </section>

        {/* <motion.div key={1} className={'section'} initial={{ opacity: 0, y: '100%' }} animate={ 1 === activeIndex ? { opacity: 1, y: '0%' } : { opacity: 0, y: (activeIndex + 1 == 1) ? '100%' : '-100%' } } exit={ (activeIndex+1) == 1 ? { opacity: 0, y: '-100%' } : { opacity: 0, y: '0%' }} transition={{ duration: 0.25 }} > */}
        <section className="sectionx w-full main-bg min-h-screen py-5 flex flex-col justify-evenly">
            <div className="py-5 px-4 mx-auto w-full max-w-screen-2xl">
                <div className='flex flex-row space-x-10'>
                    <div className="w-7/12">
                        <div className='flex flex-col space-y-5 h-full'>
                            <div>
                                <div className='w-10/12 text-5xl font-normal text-white tracking-wider leading-tight -ml-1'>Navigating the <span className='font-bold text-main'>Tech Terrain</span></div>
                            </div>
                            <div className="p-10 rounded-3xl flex-1" style={{background: '#000000'}}>
                                <div className='text-4xl font-bold tracking-wide text-white'><span><span style={{color: '#F5CAE6'}}>Frontend</span> Magic</span> <span className='px-3'>&amp;</span> <span><span style={{color: '#AFB7FF'}}>Backend</span> Wizardry</span></div>
                                <div className='grid grid-cols-4 gap-8 mt-8'>
                                {langs.map((lang) => (
                                    <div key={lang.name} className="p-3 text-white text-center main-bgx">
                                        <img className="h-14 w-14 mx-auto object-contain" src={`/images/langs/${lang.icon}.png`}/>
                                        <div className='text-lg font-semibold tracking-wider mt-3'>{lang.name}</div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-5/12">
                        <div className='flex flex-col space-y-5 h-full'>
                            <div>
                                <div className='text-5xl font-normal text-white tracking-wider leading-tight -ml-1'>Crafting <span className='font-bold text-main'>Digital Wonders</span></div>
                            </div>
                            <div className="flex px-10 py-8 rounded-3xl flex-1 items-center" style={{background: '#AFB7FF'}}>
                                <div className='w-full flex flex-row space-x-5'>
                                    <img className='h-20 w-20 contain mt-2' src='https://cdn-icons-png.flaticon.com/512/3930/3930395.png'/> 
                                    <div>
                                        <span className='text-3xl font-bold' style={{color: '#111'}}>Portals</span>
                                        <div className='text-md text-neutral-600 tracking-wide mt-2'>I also specialize in developing comprehensive portals. Whether it's an employee portal enhancing communications or a customer portal providing personalized engagement.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex px-10 py-8 rounded-3xl flex-1 items-center" style={{background: '#F5CAE6'}}>
                                <div className='w-full flex flex-row space-x-5'>
                                    <img className='h-20 w-20 contain mt-2' src='/images/webdev.png'/> 
                                    <div>
                                        <span className='text-3xl font-bold' style={{color: '#111'}}>Websites</span>
                                        <div className='text-md text-neutral-600 tracking-wide mt-2'>From elegant portfolios to dynamic e-commerce platforms, I weave digital narratives that captivate and convert.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex px-10 py-8 rounded-3xl flex-1 items-center" style={{background: '#EDE2AB'}}>
                                <div className='w-full flex flex-row space-x-5'>
                                    <img className='h-20 w-20 contain mt-2' src='/images/appdev.png'/> 
                                    <div>
                                        <span className='text-3xl font-bold' style={{color: '#111'}}>Apps</span>
                                        <div className='text-md text-neutral-600 tracking-wide mt-2'>Crafting mobile apps that provide seamless experiences, whether it's for iOS, Android, or both.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* </motion.div> */}

        {/* <motion.div key={2} className={'section'} initial={{ opacity: 0, y: '100%' }} animate={ 2 === activeIndex ? { opacity: 1, y: '0%' } : { opacity: 0, y: (activeIndex + 1 == 2) ? '100%' : '-100%' } } exit={ (activeIndex+1) == 2 ? { opacity: 0, y: '-100%' } : { opacity: 0, y: '0%' }} transition={{ duration: 0.25 }} > */}
        <section className="sectionx w-full bg-black min-h-screen flex flex-col justify-evenly">
            <div className="py-10 px-4 mx-auto w-full max-w-screen-2xl">
                <div className="mx-auto w-full max-w-screen-2xl">
                    <div className='text-5xl font-normal text-white tracking-wider leading-tight mb-5'>Projects That Define <span className='font-bold text-main'>My Expertise</span></div>
                </div>
                <div className='space-y-24'>
                    <Projects title="Portals" color="#AFB7FF" desc="Employee, Customer, Partners, Suppliers, Education, Real Estate Portals, etc etc. Basically any kind of portal."/>

                    
                    <Projects title="Websites" color="#F5CAE6" desc="Business Portfolio, E-Commerce Marketplace, Social Media, Auction, etc."/>

                    <div className='rounded-3xl bg-black'>
                        <div className="w-2/3 px-8 py-5 rounded-[10px] items-center" style={{background: '#EDE2AB'}}>
                            <div className='w-full flex flex-row space-x-5'>
                                <div>
                                    <span className='text-3xl font-bold' style={{color: '#111'}}>Apps</span>
                                    <div className='text-md text-neutral-600 tracking-wide mt-2'>Diving into mobile apps world, developed one so far but looking to build more and more.</div>
                                </div>
                            </div>
                        </div>
                        <div className='py-4'>
                            <div className={`project Apps p-5`}>
                                <div className='project-info lg:w-1/3 w-2/2'>
                                    <div>
                                        <h2>Gion - Marketing & Analytics</h2>
                                        <p>Gion is your go-to for keeping your brand in check.
                                        <br/>
                                        You get a full analytics overview of how your brand is doing in different media outlets, from social media to editorial news.
                                        <br/>
                                        You can start new projects and collaborate easily with in-app messaging.
                                        <br/>
                                        Store brand assets, see and share reports, keep track of your PR-activities and much more.</p>
                                    </div>
                                    <div>
                                        <div className='flex flex-row space-x-4'>
                                            <div>
                                                <img src='/images/app-store.png' className='h-14 object-contain'/>
                                            </div>
                                            <div>
                                                <img src='/images/google-play.png' className='h-14 object-contain'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='project-images relativex rounded-[10px] lg:w-2/3 w-2/2'>
                                    <div className='grid grid-cols-5 gap-5 p-5'>
                                        {[1,2,3,4,5].map((index) => (
                                            <img className='main-image' src={`./images/projects/apps/gion${index}.png`}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* </motion.div> */}

        <section className="sectionx w-full bg-main min-h-screen py-5 flex flex-col justify-evenly relative">
            <div className="py-10 px-4 mx-auto w-full max-w-screen-2xl">
                <div className="mx-auto w-full max-w-screen-2xl">
                    <div className='text-5xl font-normal text-white tracking-wider leading-tight mb-3'>Passion Projects: <span className='font-bold text-black'>Where Creativity Knows No Bounds</span></div>
                    <div className='text-lg font-normal text-neutral-800 tracking-wider leading-relaxed mb-5 lg:w-2/3 w-2/2'>Beyond the ordinary, there's a special corner of my portfolio dedicated to projects that truly ignite my passion or cure my boredom and burn outs.</div>
                    
                    <Projects title="Passion" color="#F5CAE6" desc="Business Portfolio, E-Commerce Marketplace, Social Media, Auction, etc."/>

                    <div className='text-xl font-medium text-black tracking-wider leading-relaxed mt-5'>Feel free to explore, be inspired, and envision how we can collaborate to bring your next digital project to life.</div>
                </div>
            </div>
        </section>

        {/*         
        <section className="sectionx w-full bg-main py-5 flex flex-col justify-evenly relative">
            <div className="py-10 px-4 mx-auto w-full max-w-screen-2xl">
                <div className="mx-auto w-full max-w-screen-2xl">
                    <div className='text-5xl font-normal text-white tracking-wider leading-tight mb-3'>Discover My <span className='font-bold text-black'>Full Range of Creations</span></div>
                    <div className='text-lg font-normal text-neutral-800 tracking-wider leading-relaxed mb-5 lg:w-2/3 w-2/2'>While my portfolio provides a sneak peek into a selection of my projects, I understand that you might be curious to explore the entirety of my work. Here's a quick summary of what you can find:</div>

                    <div className='text-xl font-medium text-black tracking-wider leading-relaxed mt-5'>Feel free to explore, be inspired, and envision how we can collaborate to bring your next digital project to life.</div>
                    
                </div>
            </div>
        </section> */}

        {/* <motion.div key={3} className={'section'} initial={{ opacity: 0, y: '100%' }} animate={ 3 === activeIndex ? { opacity: 1, y: '0%' } : { opacity: 0, y: (activeIndex + 1 == 3) ? '100%' : '-100%' } } exit={ (activeIndex+1) == 3 ? { opacity: 0, y: '-100%' } : { opacity: 0, y: '0%' }} transition={{ duration: 0.25 }} > */}
        <section className="sectionx w-full bg-black min-h-screen py-5 flex flex-col justify-evenly relative">
            {/* <Particles canvasClassName='!absolute !bg-transparent !max-h-screen !z-10' options={options} className='absolute min-h-screen h-full w-full' id="tsparticles" init={particlesInit2}/> */}
            <div className="py-5 px-4 mx-auto w-full max-w-screen-2xl">
                <div className='flex flex-row space-x-10'>
                    <div className="w-1/2">
                        <div className='flex flex-col space-y-8 h-full'>
                            <div>
                                <div className='text-7xl font-normal text-white tracking-wider leading-tight'>Let's <span className='font-bold text-main'>Collaborate and Create</span></div>
                                <div className='mt-3 text-lg leading-loose'>Whether you're a startup with big dreams or a company looking to level up, let's work together to create exceptional digital solutions.</div>
                            </div>
                            <div>
                                <a href="mailto:usmanmalikwebdev@gmail.com" className='btn btn-main !py-5 !px-10 text-lg'>Email: UsmanMalikWebDev@gmail.com</a>
                            </div>
                            <div className='flex flex-row space-x-10'>
                                <a href="mailto:usmanmalikwebdev@gmail.com"><img src="/images/socials/email.png" className="h-16 w-16 object-contain"/></a>
                                <a href="https://linkedin.com/UsmanMalikWeb"><img src="/images/socials/linkedin.png" className="h-16 w-16 object-contain"/></a>
                                <a href="https://twitter.com/UsmanMalikWeb"><img src="/images/socials/twitter.png" className="h-16 w-16 object-contain"/></a>
                                <a href="https://instagram.com/Malikstagramm"><img src="/images/socials/instagram.png" className="h-16 w-16 object-contain"/></a>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className='flex flex-col space-y-5 h-full'>
                        <div className="p-10 rounded-3xl flex-1 main-bg" style={{background:'#B685FF'}}>
                            <div className='text-4xl font-bold tracking-wide text-black'>Coding Dreams into <span className='text-purple-800'>Reality</span></div>
                            <div className='text-lg leading-loose text-neutral-700 mt-5'>
                                Every pixel, every function, every interactive moment – they're threads of the digital tapestry I weave. Reach out, and let's transform your ideas into lines of code that inspire and engage!
                            </div>
                        </div>
                        <div className="p-10 rounded-3xl main-bg" style={{background:'#111'}}>
                            <div className='text-lg leading-loose text-neutral-500'>
                                My pipedream is for exterrerterial contact; preferrably a ship coming to Earth. I'd settle for being a coloniser on Mars though.
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* </motion.div> */}

        
        </main>
        </motion.div>
        </>
        )}
        </AnimatePresence>
    </HomeLayout>
  )
}
