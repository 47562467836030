const Portals = [

{ name: 'Freelance Fellow', description: 'Customer Management with document sharing and knowledge base', link: 'https://freelancefellow.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Gion', description: 'Find new influencers, monitor your brand and collaborate on projects.', link: 'https://gion.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Klimaatoffertes', description: 'Leads management and sharing with partners', link: 'https://klimaatoffertes.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Taxflex Portal', description: 'Taxflex helps start-ups and micro businesses with their company accounts filing needs.', link: 'https://taxflex.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Your Barber App', description: 'SaaS for Barber Appointments Management', link: 'https://BarberApp.developmint.xyz/', passion: '0', template: '0' },
{ name: 'FineTurtle', description: 'NFT Sales Portal for Investors and Sponsors', link: 'https://fineturtle.developmint.xyz/', passion: '0', template: '0' },
{ name: 'AZ Flat Fee', description: 'Property Management Portal', link: 'https://AzFlatFee.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Txat', description: 'Customer & Rep Management Portal', link: 'https://txat.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Crypfx', description: 'Customer Management with withdrawal requests and chat', link: 'https://crypfx.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Birch Group', description: 'Portal for keeping record of the Social Value.', link: 'https://birchgroup.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Alexi Exchange', description: 'Alexi Exchange is a private platform where lawyers and investors can trade, invest, and participate in mass tort legal cases.', link: 'https://alexiexchange.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Kairos', description: 'Electronic Cargo Tracking Note', link: 'https://kairos.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Fort Funding', description: 'Portal for saving scenarios and lenders management', link: 'https://fortfunding.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Cinematic Portal', description: 'Custom Portal for getting feedbacks from individual client about their video project', link: 'https://cinematic.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Reports Detective', description: 'Employee Management Portal', link: 'https://reports-detective.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Post Solution', description: 'Temporary mailing address with scan & burn, scan & ship feature', link: 'https://postsolution.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Australian Will Directory', description: 'A Portal that aims to gather all law firms, lawyers, and wills records in one place.', link: 'https://auswilldirectory.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Smooth Landings Canada', description: 'Customer Portal with resources and steps to become a Canadian Citizen', link: 'https://smoothlandings.developmint.xyz/', passion: '0', template: '0' },
{ name: 'RT Portal', description: 'Customers, Products, Orders Management Portal', link: 'https://rt.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Cipher Strategies', description: 'Customer Portal with Knowledge Base and Ticket System', link: 'https://cipher.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Regen', description: 'Waste Management Company', link: 'https://regen.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Wapz Airport Transfers', description: 'Invoice portal for Drivers and Partners', link: 'https://wapz.developmint.xyz/', passion: '0', template: '0' },
{ name: '360 One Stop', description: 'A portal for a company that offers home care services to its clients.', link: 'https://360onestop.developmint.xyz/', passion: '0', template: '0' },
{ name: 'ARO Management', description: 'Custom portal for customer and employee management', link: 'https://aromanagement.developmint.xyz/', passion: '0', template: '0' },
{ name: 'I Deserve It', description: 'Knowledge Base for Membership Users', link: 'https://ideserveit.developmint.xyz/', passion: '0', template: '0' },
{ name: 'BBall', description: 'Knowledge for Basketball Training', link: 'https://Bball.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Brandon Michaels Group Portal', description: 'Managing Property data for the clients to view', link: 'https://bmg.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Brick Lane Group Portal', description: 'Property Investment and News', link: 'https://BrickLane.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Calisto Partners', description: 'Personal Hotel Bookings Management', link: 'https://Calistoco.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Ctunes Production', description: 'Portal for booking recording and mixing sessions', link: 'https://ctunes.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Custom Calibration', description: 'Record management portal for admin and customers', link: 'https://customcalibration.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Easy Hands International', description: 'Staff Working Hours Management Portal', link: 'https://easyhands.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Ideal Education Group', description: 'E-learning management portal', link: 'https://idealedu.developmint.xyz/', passion: '0', template: '0' },
{ name: 'EMS Reporting', description: 'Pre Hospital Incident report management portal', link: 'https://emsreporting.developmint.xyz/', passion: '0', template: '0' },
{ name: 'EQPOS Software Solutions', description: 'Restaurant menu and employee management', link: 'https://eqpos.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Finance Portal', description: 'Manage Everyday Finances', link: 'https://finance.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Fleurs Friendly Fields', description: 'Dog Training Portal', link: 'https://fleurs.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Habbo', description: 'Didn\'t know what it does, still don\'t know :p', link: 'https://habbo.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Investment Portal Sample', description: 'Showcase customer\'s investment progress', link: 'https://Investment.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Journey Biosciences', description: 'Portal for Clinics to Order Lab Tests and get Results', link: 'https://journey.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Joyner Transportation', description: 'Client Management for Transportation Company', link: 'https://joyner.developmint.xyz/', passion: '0', template: '0' },
{ name: 'LA Jolla Band', description: 'Employee management, knowledge base, ticket system', link: 'https://lajollaband.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Lien Search Nation', description: 'Order management portal', link: 'https://liensearchnation.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Nadine Tom', description: 'Employee, Manager, Staff Management', link: 'https://nadinetom.developmint.xyz/', passion: '0', template: '0' },
{ name: 'NSR Tax & Accounting', description: 'Customer Management Portal', link: 'https://nsrtax.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Ordiginal', description: 'Resellers, Employees, Tickets Management', link: 'https://ordiginal.developmint.xyz/', passion: '0', template: '0' },
{ name: 'ParentingU', description: 'Community Programs', link: 'https://parentingu.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Private Diners Club', description: 'Customer Event Management', link: 'https://privatedinersclub.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Sculpt Pro Aesthetics', description: 'Customer Portal', link: 'https://sculptpro.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Shall Cross', description: 'Company Agents Portal', link: 'https://shallcross.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Strat Prop', description: 'Property Management Portal', link: 'https://stratprop.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Tasks Management', description: 'Company Task Management Portal', link: 'https://taskmanagement.developmint.xyz/', passion: '0', template: '1' },
{ name: 'telink reseller portal', description: 'Platform for license and plans activation', link: 'https://telink.developmint.xyz/', passion: '0', template: '0' },
{ name: 'The Write Lady', description: 'Portal that provides online course learning for their customers', link: 'https://theewritelady.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Trafford Enterprise Customer Portal', description: 'Trafford organizes events, records pledges and build connections between people', link: 'https://trafford.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Hotcust', description: 'Order management portal', link: 'https://hotcust.developmint.xyz/', passion: '0', template: '0' },

];

export default Portals;