import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/carousel.css'
import Portals from './Portals';
import Websites from './Websites';
import Passion from './Passion';

import { PiArrowSquareRightFill, PiArrowSquareLeftFill } from 'react-icons/pi';
import LazyImage from './LazyImage';

const Projects = ({ title, color, desc }) => {

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-prev-arrow" onClick={onClick}>
          <PiArrowSquareLeftFill/>
        </div>
    );
    
    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-next-arrow" onClick={onClick}>
            <PiArrowSquareRightFill/>
        </div>
    );

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      focusOnSelect: true,
    //   prevArrow: <CustomPrevArrow />,
    //   nextArrow: <CustomNextArrow />,
    };

    let projectsArray = [];
  
    if (title === 'Websites') {
      projectsArray = Websites;
    } else if (title === 'Portals') {
      projectsArray = Portals;
    } else if (title === 'Passion') {
        projectsArray = Passion;
      }

    return (
        <div>
            <div className=''>
                {title != 'Passion' && 
                <div className='flex flex-row space-x-4'>
                <div className="w-2/3 px-8 py-5 rounded-[10px] items-center" style={{background: color}}>
                    <div className='w-full flex flex-row space-x-5'>
                        <div>
                            <span className='text-3xl font-bold' style={{color: '#111'}}>{title}</span>
                            <div className='text-md text-neutral-600 tracking-wide mt-2'>{desc}</div>
                        </div>
                    </div>
                </div>
                <div className='w-1/3'></div>
                </div>
                }
                <div className='py-4'>
                    <Slider {...settings}>
                        
                        {projectsArray.map((temp, index) => (
                        <div className={`project ${(title == 'Passion' ? title+' Websites' : title)}`} key={title+index}>
                            <div className='project-images relativex rounded-[10px]'>
                                <LazyImage cls='main-image' src={`./images/projects/${title.toLowerCase()}/${((((temp.link).replace('index.html', '')).replace('https://', '')).replace('.developmint.xyz/', '')).toLowerCase()}.jpg`} alt={temp.name} />
                                {/* <img className='main-image' src={`./images/projects/${title.toLowerCase()}/${((((temp.link).replace('index.html', '')).replace('https://', '')).replace('.developmint.xyz/', '')).toLowerCase()}.jpg`}/> */}
                                { (temp.name != 'VocabuZest' && temp.name != 'Gym Tracker') &&
                                
                                <LazyImage cls='sec-image' src={`./images/projects/${title.toLowerCase()}/${((((temp.link).replace('index.html', '')).replace('https://', '')).replace('.developmint.xyz/', '')).toLowerCase()}.png`} alt={temp.name} />

                                // <img className='sec-image' src={`./images/projects/${title.toLowerCase()}/${((((temp.link).replace('index.html', '')).replace('https://', '')).replace('.developmint.xyz/', '')).toLowerCase()}.png`}/>
                                }
                            </div>
                            <div className='project-info'>
                                <div>
                                    <h2>{temp.name}</h2>
                                    <p>{temp.description}</p>
                                </div>
                                <div>
                                    <a href={temp.link} target="_blank" rel="noopener noreferrer">{temp.link}</a>
                                </div>
                            </div>
                        </div>
                        ))}

                    </Slider>

                </div>    
            </div>
        </div>
    )
}

export default Projects;

