const Websites = [

{ name: 'TokenDen', description: 'Premium NFT Investments for everyone', link: 'https://tokenden.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Comfy Business', description: 'Portfolio website for Comfy Business LLC', link: 'https://comfybusiness.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Lawn Maintenance Template', description: 'Lawn Maintenance Company Portfolio', link: 'https://lawnmaintenance.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Pay My Deductible', description: 'Empowering rideshare host with the best asset protection solution.', link: 'https://paymydeductibles.developmint.xyz/', passion: '0', template: '0' },
{ name: 'JustSell', description: 'JustSell connects people with nearby service providers across the internet.', link: 'https://justsell.developmint.xyz/', passion: '0', template: '0' },
{ name: 'HexoSafe', description: 'A decentralized way of communication across the blockchains ', link: 'https://hexosafe.developmint.xyz/', passion: '0', template: '0' },
{ name: 'BellPall', description: 'Landing Page for Safety+', link: 'https://BellPall.developmint.xyz/', passion: '0', template: '0' },
{ name: 'The Event | Template', description: 'Conference Event Landing Page', link: 'https://event.developmint.xyz/index.html', passion: '0', template: '1' },
{ name: 'Select Capital Group', description: 'Provides Investment for property/rental loans or Hard Money loans', link: 'https://selectcapitalgroup.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Real Estate | Template', description: 'Landing page for real estate company', link: 'https://realestate.developmint.xyz/', passion: '0', template: '1' },
{ name: 'SoleRemit', description: 'SoleRemit lets you send money abroad with a smartphone, tablet or computer.', link: 'https://soleremit.developmint.xyz/', passion: '0', template: '0' },
{ name: 'WeBuild | Template', description: 'Construction company landing page', link: 'https://webuild.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Finestate', description: 'Real Estate NFT Marketplace', link: 'https://Finestate.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Privatell', description: 'Monitor your parking lot and earn money from every illegal parker caught', link: 'https://privtell.developmint.xyz/', passion: '0', template: '0' },
{ name: 'ZebraWay Language Club', description: 'An online language learning club based on innovative methods for elastic language learning.', link: 'https://zebraway.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Anna Salman Dar', description: 'Portfolio of One of My Favourite Music Artist', link: 'https://AnnaSalman.developmint.xyz/', passion: '0', template: '1' },
{ name: 'AutoParts Store', description: 'Online store for autoparts', link: 'https://autoparts.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Scenario Prep', description: 'A simple consolidated place to store all kinds of information and personal wishes in relation to urgent situations.', link: 'https://scenarioprep.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Saboor Bearing', description: 'Business Portfolio', link: 'https://saboorbearing.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Top Ref Services', description: 'We organize basketball and football games, and also provide referees and coaching services', link: 'https://toprefservices.developmint.xyz/', passion: '0', template: '0' },
{ name: 'AdBreeze', description: 'Custom Design Only', link: 'https://AdBreeze.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Calvary', description: 'A one time event booking site', link: 'https://calvary.developmint.xyz/', passion: '0', template: '0' },
{ name: 'The Diamond Bar', description: 'Portfolio', link: 'https://thediamondbar.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Ebiz', description: 'Ecommerce store for shoes', link: 'https://ebiz.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Event Vila', description: 'Marketplace for both Service Providers and Clients to work together', link: 'https://eventvila.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Flexpool', description: 'Mining Pool Website Template', link: 'https://Flexpool.developmint.xyz/', passion: '0', template: '1' },
{ name: 'MV Auction', description: 'Multi Vendor Auction Website', link: 'https://mvauction.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Notablee', description: 'E-Learning platform for Students and Educators', link: 'https://notablee.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Poosto | Template', description: 'Platform for Supplier and Agency to collaborate', link: 'https://poosto.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Premier Financial Network', description: 'Serving your Investment, Insurance, and Financial Needs', link: 'https://premierfinancialnetwork.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Real Estate | Template', description: 'Search Properties for Buy & Rent', link: 'https://propertydesign.developmint.xyz/', passion: '0', template: '1' },
{ name: 'Resource Realty', description: 'Providing real estate, legal, mortgages, and other related services', link: 'https://resourcerealty.developmint.xyz/', passion: '0', template: '0' },
{ name: 'Rosemantic', description: 'Ecommerce store for flowers', link: 'https://rosemantic.developmint.xyz/', passion: '0', template: '0' },

];

export default Websites;