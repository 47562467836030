import '../styles/header.css'
import { Link, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

export default function Header() {

  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isMobileView = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    if (isMobileView) {
      isMenuOpen
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }
  }, [isMenuOpen, isMobileView]);

  
  return (
    <>
    <nav className={`absolute w-full mx-auto ${ isMenuOpen ? 'toggled' : 'pr-3 lg:pr-0' } ${location.pathname == '/' && ''}`}>
    <div className="containerx max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto md:py-8 pt-5 pb-8 px-4">
    <div className="flex items-center mr-4">
      <Link to="/" className="flex items-center space-x-4">
        <img src='/logo.png' className='h-12'/>
      </Link>
    </div>
    <div className="flex md:hidden">
        <button data-collapse-toggle="navbar-cta" type="button" className="inline-flex justify-center items-center p-2 text-sm text-gray-500 rounded-lg md:hidden toggler" aria-controls="navbar-cta" aria-expanded={isMenuOpen} onClick={toggleMenu} >
            <span className="sr-only">Open main menu</span>
              <svg
                className="w-8 h-8"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                  animate={{ rotate: isMenuOpen ? 45 : 0, y: isMenuOpen ? 5 : 0 }}
                  initial={{ rotate: 0, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                />
                <motion.path
                  fillRule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                  animate={{ opacity: isMenuOpen ? 0 : 1 }}
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                />
                <motion.path
                  fillRule="evenodd"
                  d="M3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                  animate={{ rotate: isMenuOpen ? -45 : 0, y: isMenuOpen ? -5 : 0 }}
                  initial={{ rotate: 0, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                />
              </svg>
        </button>
    </div>
    <motion.div 
            initial={isMobileView ? { x: '100%' } : {}}
            animate={isMobileView ? { x: isMenuOpen ? '0%' : '100%' } : {}}
            exit={isMobileView ? { x: '100%' } : {}}
            transition={{ type: 'tween', duration: 0.3 }}
            className={`items-center justify-between lg:flex lg:flex-1 md:contents md:order-1 w-full ${
              isMobileView && !isMenuOpen ? 'hiddenx' : ''
            }`} id="navbar-cta">
        <div className='flex h-full flex-1'>

          <div className="flex flex-col items-center justify-center flex-1 p-4 md:p-0 mt-4 ml-0 lg:ml-10 font-medium rounded-lg md:flex-row space-x-8 lg:space-x-10 md:mt-0 md:border-0 nav-links">
              {/* <div><Link to="/download">Download</Link></div>
              <div><Link to="/pricing">Pricing</Link></div>
              <div><Link to="/features">Features</Link></div>
              <div><Link to="/">Support</Link></div> */}
          </div>
          
          <div className='sm-toggler'>
            <div className=' w-full flex flex-col justify-between'>
              <div className='w-full p-3 text-white text-2xl flex flex-col space-y-5 text-right mt-16'>
                <div><Link to="/">Home</Link></div>
                <div><Link to="/download">Download</Link></div>
                <div><Link to="/pricing">Pricing</Link></div>
                <div><Link to="/features">Features</Link></div>
                <div><Link to="/">Support</Link></div>
              </div>
              <div>
                <div className="flex flex-col space-y-5 p-3 text-lg text-right">
                  <Link to="/privacy" className="text-neutral-500 hover:text-neutral-700">Privacy Policy</Link>
                  <Link to="/terms" className="text-neutral-500 hover:text-neutral-700">Terms and Conditions</Link>
                </div>
                <div className='flex flex-row justify-end space-x-3 px-3'>
                    <div><Link to="/signup" className="btn-main !rounded-md flex-1 !px-3">Log in</Link></div>
                    <div><Link to="/login" className="btn-main-alt !rounded-md flex-1 !px-3">Sign up for free</Link></div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row justify-end ml-4 nav-buttons'>
              <div><Link to="/signup" className="btn-main">Contact</Link></div>
          </div>

      </div>
    </motion.div>
    </div>
    </nav>
    </>
  )
}
